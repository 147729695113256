<template>
  <div class="analysisDetails-page">
    <div class="main">
      <div class="top">
        <!-- 返回按钮 -->
        <el-button class="return-button" @click="$router.back()"></el-button>

        <!-- 模式 -->
        <div style="width: 12vw">
          <el-descriptions :labelStyle="{ 'font-size': '0.0469rem' }">
            <el-descriptions-item label="当前模式">
              <el-switch
                v-model="isWorkMode"
                active-color="#409EFF"
                inactive-color="#ff4949"
                :active-text="isWorkMode ? '工作模式' : '预览模式'"
                :title="isWorkMode ? '工作模式支持预览和标记图片缺陷' : '预览模式仅支持查看图片'"
                v-if="$hasElement('errand-btn-workMode')"
              ></el-switch>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <div class="bottom">
        <!-- 图片列表 -->
        <div class="left">
          <div class="son-header">
            <i class="el-icon-picture-outline"></i>
            图片列表
          </div>
          <div class="img-tabs">
            <el-tabs v-model="typeActive" @tab-click="handleClick" stretch>
              <el-tab-pane v-for="(status, index) in photoStatusList" :key="index" :label="status.value" :name="status.name">
                <ul v-if="typeActive == status.name" class="photo-list infinite-list" v-infinite-scroll="loadMorePhoto">
                  <li :class="currentIndex == index ? 'li-actived' : ''" v-for="(photo, index) in allPhotoList" :key="index" @click="getPhotoDetails(allPhotoList, index)">
                    <i class="el-icon-picture" style="margin-right:1vh"></i>
                    {{ photo.photoName }}
                  </li>
                </ul>
              </el-tab-pane>
              <div style="position:absolute;bottom:0;display:flex;justify-content: center;align-items: center;width:100%">
                <p style="font-size:1.4vh;">共 {{ totalPhoto }} 张</p>
                <p style="width:.5vh;"></p>
                <p style="font-size:1.4vh;">第 {{ currentIndex+1 }} 张</p>
              </div>
            </el-tabs>
          </div>
        </div>

        <!-- 大图 -->
        <div class="middle">
          <div class="middle-header">
            <div>当前检测任务：{{ $route.query.taskName }}</div>
            <div>拍摄位置：{{ (currentPhoto.towerName ? $route.query.lineName +  "_" + currentPhoto.towerName : "") }}</div>
            <div>图片名称：{{ currentPhoto.photoName || "无" }}</div>
            <div>图片状态：{{imgStatus}}</div>
            <div>{{totalPhoto != 0 ? "第:" + ((currentIndex + 1) + "/" + (totalPhoto)) + "张" : "无"}} </div>
            <div>查看进度：{{ totalPhoto == 0 ? 0 : Math.floor(((currentIndex + 1) / totalPhoto) * 100) + "%" }}</div>
          </div>
          <div class="photo-area">
            <div v-if="allPhotoList.length == 0 || !loadImageSuccess" style="text-align:center;padding:40vh;">
              <span>未选择图片或图片不存在！</span>
            </div>
            <div v-else @mouseenter="handleShowButton('enter')" @mouseleave="handleShowButton('leave')">
              <i class="switch-button prev-button el-icon-arrow-left" v-show="isShowButton" title="切换到上一张" @click="handleChangePhoto('prev')" />
              <i class="switch-button next-button el-icon-arrow-right" v-show="isShowButton" title="切换到下一张" @click="handleChangePhoto('next')" />

              <ui-marker
                v-loading="isLoadingImage"
                element-loading-text="加载图片中..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                ref="aiPanel-editor"
                class="ai-observer"
                @vmarker:onImageLoad="imageLoadFun"
                @vmarker:onReady="readyFun"
                @vmarker:onAnnoAdded="annoAddedFun"
                @vmarker:onAnnoSelected="annoSelectedFun"
                @vmarker:onUpdated="annoUpdatedFun"
                @vmarker:onAnnoDataFullLoaded="annoDataFullLoadedFun"
                @vmarker:onAnnoRemoved="annoRemoveFun"
                @vmarker:onAnnoChanged="annoChangedFun"
                :uniqueKey="currentPhoto.photoUrl"
                :imgUrl="currentPhoto.photoUrl"
                :readOnly="!isWorkMode"
              ></ui-marker>
            </div>
          </div>
        </div>

        <!-- 图片管理 -->
        <div class="right">
          <div class="son-header">
            <Icon name="defect" class="icon-class" />
            图片管理
          </div>

          <div class="top-button">
            <!-- <el-button class="kr-normal-button" @click="reloadPhotoDialogVisible = true">重新加载</el-button> -->
            <el-button class="kr-normal-button" @click="handleClickDefect">缺陷管理</el-button>
            <el-button class="kr-normal-button" disabled>统计数据</el-button>
            <el-button class="kr-normal-button" disabled>智能分析</el-button>
            <el-button class="kr-normal-button" style="margin: 0" disabled>检测图片</el-button>
          </div>

          <div class="sub-header">
            <!-- <Icon name="defect" class="icon-class" /> -->
            图片缺陷信息
          </div>
          <div class="photo-defects" style="overflow-y: auto;" :style="{ height: isWorkMode ? '28vh' : '66vh' }">
            <div class="defect-list" v-for="(defect, index) in photoDefects" :key="index">
              <el-descriptions class="defect-desc" :column="1" size="normal">
                <el-descriptions-item label="缺陷名称">
                  {{ defect.defectName }}
                  <i
                    class="iconfont"
                    :class="[defect.isShowInPicutre ? 'icon-xianshi_huaban' : 'icon-yincangdaan']"
                    style="color: yellow; margin-left: 0.5vw"
                    :title="defect.isShowInPicutre ? '在图片上隐藏此缺陷' : '在图片上显示此缺陷'"
                    @click="handleShowOrHideDefect(index, defect.isShowInPicutre)"
                  ></i>
                </el-descriptions-item>
                <el-descriptions-item label="缺陷等级">
                  {{ showDefectLevel(defect.defectLevel) }}
                </el-descriptions-item>
                <el-descriptions-item label="检测类型">
                  {{ showRecognizeType(defect.defectType) + " (" + showDefectStatus(defect.defectStatus) + ")" }}
                </el-descriptions-item>
                <el-descriptions-item label="检测时间">
                  {{ defect.updateTime }}
                </el-descriptions-item>
                <el-descriptions-item label="详细描述">
                  {{ defect.defectDetail }}
                </el-descriptions-item>
                <el-descriptions-item label="备注内容">
                  {{ defect.remark }}
                </el-descriptions-item>
                <el-descriptions-item label="操作">
                  <div style="display: flex; flex-direction:row;">
                    <el-tag class="my-tag el-icon-edit-outline" title="删除此缺陷" @click="handleUpdateDefect(defect)">编 辑</el-tag>
                    <el-tag class="my-tag el-icon-delete" title="删除此缺陷" @click="handleDeleteDefect(defect.id)">删 除</el-tag>
                  </div>
                </el-descriptions-item>
              </el-descriptions>
              <el-divider></el-divider>
            </div>
          </div>

          <div class="sub-header" v-if="isWorkMode">
            <!-- <Icon name="defect" class="icon-class" /> -->
            人工审阅
          </div>
          <el-tabs v-if="isWorkMode" v-model="reviewActive" stretch>
            <el-tab-pane label="确认正常" name="normal">
              <div style="display: flex; flex-direction: row; justify-content: center">
                <el-button class="kr-normal-button" @click="savePhotoNormal">图片无异常</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="添加异常" name="defect">
              <div class="photo-defect" v-if="isWorkMode">
                <div>
                  <el-input
                    class="normal-input"
                    style="padding: 5px; width: 12vw"
                    size="mini"
                    placeholder="请输入缺陷关键字"
                    suffix-icon="el-icon-search"
                    v-model="defectKeyWord"
                    @input="fuzzyGetDefect"
                  />
                  <el-checkbox-group style="margin: 1vh; height: 19vh; overflow-y: auto;" v-model="selectTagNames" @change="addTagToMarker()">
                    <el-checkbox v-for="(defect, index) in filterDefectList" :key="index" :label="defect.defectName"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="save-button">
                  <el-button class="kr-normal-button" @click="savePhotoDefectMarker">保 存 缺 陷</el-button>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>


    <!-- 图片缺陷类别管理 -->
    <el-dialog :visible.sync="diyDefectTableVisible" title="图片缺陷类别管理" :close-on-click-modal="false" class="my-dialog2 defect-dialog" width="70%" top="10vh">
      <div style="display: flex; flex-direction: row; justify-content: flex-end; width: 100%; margin-bottom: 1vh; ">
        <el-button class="kr-normal-button" @click="filterDefectDialogVisible = true" v-if="$hasElement('errand-btn-getDefect')">过滤缺陷</el-button>
        <el-button class="kr-normal-button" @click="addDiyDefectBtn" v-if="$hasElement('errand-btn-addDefect')">添加缺陷</el-button>
      </div>
      <div>
        <el-table :data="manageDefectList" style="width: 100%" height="60vh">
          <el-table-column label="序号" width="100">
            <template slot-scope="scope">
              <!-- <Icon name="bottomArrow" :key="uniqueId"/> -->
              <i class="el-icon-arrow-right table-index-icon" />
              <span>{{ (filterDefectForm.pageNumber - 1) * filterDefectForm.pageSize + scope["$index"] + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="defectName" label="名称" width="200"></el-table-column>
          <el-table-column prop="defectLevel" label="缺陷来源" align="center" width="150">
            <template slot-scope="scope">
              <span>{{ showDefectSource(scope.row.defectSource) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="defectLevel" label="缺陷等级" align="center" width="150">
            <template slot-scope="scope">
              <span>{{ showDefectLevel(scope.row.defectLevel) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="creatorName" label="创建人" align="center"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" width="200"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button class="table-btn" type="text" @click="editBtn(scope.row)" v-if="$hasElement('errand-btn-updateDefect')"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
              <el-button class="table-btn" type="text" @click="deleteDefect(scope.row)" v-if="$hasElement('errand-btn-deleteDefect')"><i class="el-icon-delete" style="color:#fff;font-size:1.5vh;"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-pagination
          :current-page.sync="filterDefectForm.pageNumber"
          @current-change="getDefectList()"
          :page-size="filterDefectForm.pageSize"
          :pager-count="5"
          layout="total, prev, pager, next"
          :total="totalDefect"
        ></el-pagination>
      </span>
    </el-dialog>

    <!-- 新增自定义缺陷弹框 -->
    <el-dialog class="my-dialog" :close-on-click-modal="false" title="新增自定义缺陷" :visible.sync="addDefectDialogVisible" @close="initdefectForm('defectForm')" width="20%" top="30vh">
      <el-form :model="defectForm" :rules="defectFormRules" ref="defectForm" >
        <el-form-item label="缺陷名称" prop="defectName">
          <el-input v-model="defectForm.defectName"></el-input>
        </el-form-item>
        <el-form-item label="缺陷等级" prop="defectLevel">
          <el-select v-model="defectForm.defectLevel" placeholder="请选择缺陷等级">
            <el-option v-for="(item, index) in defectLevels" :key="index" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺陷描述" prop="defectDesc">
          <el-input v-model="defectForm.defectDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="addDefectDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="saveDiyDefect('defectForm')">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 过滤缺陷弹框 -->
    <el-dialog class="my-dialog small-dialog" :close-on-click-modal="false" title="过滤图片缺陷" :visible.sync="filterDefectDialogVisible" width="20%" top="30vh">
      <el-form :model="filterDefectForm" ref="filterDefectFormRef">
        <el-form-item label="缺陷名称" prop="detail">
          <el-input v-model="filterDefectForm.fuzzyName" placeholder="请输入缺陷名称关键字"></el-input>
        </el-form-item>
        <el-form-item label="缺陷等级" prop="defectLevel">
          <el-select v-model="filterDefectForm.defectLevel" placeholder="请选择缺陷等级">
            <el-option v-for="(item, index) in defectLevelList" :key="index" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺陷来源" prop="defectSource">
          <el-select v-model="filterDefectForm.defectSource" placeholder="请选择缺陷来源">
            <el-option v-for="(item, index) in defectSourceList" :key="index" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="applyFilterDefect(false)">重 置</el-button>
        <el-button class="save-button" @click="applyFilterDefect(true)">应 用</el-button>
      </span>
    </el-dialog>

    <!-- 重新加载巡检图片弹窗 -->
    <el-dialog class="my-dialog" :close-on-click-modal="false" title="加载巡检图片" :visible.sync="reloadPhotoDialogVisible" top="20vh">
      <div class="defect-msg-input">
        <el-form :model="photoForm" :rules="photoFormRules" ref="photoForm" >
          <el-form-item label="巡检线路" prop="defectPosition">
            <el-select v-model="photoForm.lineIds" placeholder="请选择巡检线路" clearable multiple @change="updateDefectSelector('position')">
              <el-option v-for="(line, index) in lineList" :key="index" :label="line.lineName" :value="line.lineId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期" prop="beginDate">
            <markDatePicker ref="startSelector" v-bind:has-photo-dates="hasPhotoDates" tag="beginDate" @date-btn-click="datePickerBtnClick" @change-date="changeDate"></markDatePicker>
          </el-form-item>
          <el-form-item label="结束日期" prop="endDate">
            <markDatePicker ref="endSelector" v-bind:has-photo-dates="hasPhotoDates" tag="endDate" @date-btn-click="datePickerBtnClick" @change-date="changeDate"></markDatePicker>
          </el-form-item>
          <el-form-item label="检测任务" prop="defectType">
            <el-select v-model="photoForm.errandIds" placeholder="请选择检测任务" clearable multiple>
              <el-option v-for="(errand, index) in errandList" :key="index" :label="errand.errandName" :value="errand.errandId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button">取 消</el-button>
        <el-button class="save-button">应 用</el-button>
      </span>
    </el-dialog>

    <!-- 保存缺陷对话窗口 -->
    <el-dialog class="my-dialog" :close-on-click-modal="false" title="保 存 缺 陷" :visible.sync="saveDefectDialogVisible" top="20vh" @close="handleCloseDialog('defectRegionFormRef')">
      <el-form :model="defectRegionForm" :rules="defectRegionFormRules" ref="defectRegionFormRef" label-width="100px">
        <el-form-item label="巡检图片">
          <el-input disabled v-model="currentPhoto.photoName"></el-input>
        </el-form-item>
        <el-form-item label="识别类型" prop="defectType">
          <el-select v-model="defectRegionForm.defectType" placeholder="请选择识别类型" clearable filterable>
            <el-option v-for="(type, index) in recognizeTypeList" :key="index" :label="type.value" :value="type.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺陷状态" prop="defectStatus">
          <el-select v-model="defectRegionForm.defectStatus" placeholder="请选择缺陷状态" clearable filterable>
            <el-option v-for="(type, index) in defectStatusList" :key="index" :label="type.value" :value="type.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺陷类型" prop="defectId">
          <el-select v-model="defectRegionForm.defectId" placeholder="请选择缺陷类型" clearable filterable>
            <el-option v-for="(defect, index) in allDefectList" :key="index" :label="defect.defectName" :value="defect.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺陷详情" prop="defectDetail">
          <el-input v-model="defectRegionForm.defectDetail" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入缺陷详情"></el-input>
        </el-form-item>
        <el-form-item label="备注内容" prop="remark">
          <el-input v-model="defectRegionForm.remark" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入备注内容"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="hanldeSaveDefect('defectRegionFormRef', false)">取 消</el-button>
        <el-button class="save-button" @click="hanldeSaveDefect('defectRegionFormRef', true)">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Icon from "@/components/Icon.vue";
import { AIMarker } from "vue-picture-bd-marker";
import markDatePicker from "../../components/photoDisplay/markDatePicker.vue";
import {
  getSelectPhotos,
  getselectSinglePhoto,
  postDefectDetailsByName,
  getDefectLevels,
  getDefectSources,
  getRecognizeTypes,
  getDefectStatus,
  getAllDefect,
  filterDefect,
  saveDefect,
  updateDefect,
  deleteUserDefect,
  getPhotoDefects,
  updatePhotoDefect,
  addPhotoDefect,
  deletePhotoDefect,
  updatePhotoStatus
} from "@/http/analysisDetailsApi.js";
// import moment from "moment";
import * as randomUtil from "@/utils/js/randomUtil.js";
import { md5Encryption2 } from "@/utils/js/md5.js"
export default {
  name: "analysisDetails",

  components: {
    Icon,
    "ui-marker": AIMarker,
    markDatePicker
  },

  computed: {
    defectLevels: function() {
      return this.defectLevelList.filter(d => {
        return d.value?.indexOf("全部等级") == -1;
      });
    },

    /*
     * @Date 2022/06/10 09:22:25
     * 图片状态
     */
    imgStatus: function() {
      let result = this.photoStatusList.find(statusObj => {
        return statusObj.name == this.currentPhoto.photoStatus
      });
      if(result) {
        return result.value
      }else {
        return "未知"
      }
    }
  },

  data() {
    return {
      /*
       * @Date 2022/06/24 14:25:16
       * 模式信息
       */
      isWorkMode: false,      // false: 预览模式; true: 工作模式


      /*
       * @Date 2022/06/24 14:26:25
       * 图片列表信息
       */
      typeActive: "PHOTO_STATUS_UNCHECK",   // 当前选择的图片类别
      photoStatusList: [                    // 图片类别列表
        { name: "PHOTO_STATUS_DEFECT", value: "缺陷" },
        { name: "PHOTO_STATUS_NORMAL", value: "正常" },
        // 取消图片的误检分类
        // { name: "PHOTO_STATUS_MISTAKE", value: "误检" },
        { name: "PHOTO_STATUS_UNCHECK", value: "未检测" }
      ],
      allPhotoList: [],                    // 图片列表
      totalPhoto: 0,                       // 图片总数
      currentIndex: 0,                     // 当前照片的下标
      

      /*
       * @Date 2022/06/24 15:01:26
       * 中间图片信息
       */
      currentPhoto: "",                // 当前图片
      loadImageSuccess: false,         // 图片是否加载完成
      isLoadingImage: false,           // 是否正在加载图片
      isShowButton: false,             // 是否展示轮播图前后按钮
      allMarker: [],                   // 当前图片上标注的所有缺陷，保存时传给后台
      newDefectName: "",
      isUpdate: false,                 // 是否有添加和移动标记框
      selectedUUID: "",                // 选中的标签的uuid
      currentAnnoData: [],             // 当前缺陷区域列表



      /*
       * @Date 2022/06/24 15:20:25
       * 缺陷管理信息
       */
      diyDefectTableVisible: false,       // 是否打开缺陷管理对话框
      manageDefectList: [],               // 缺陷列表
      totalDefect: 0,                     // 缺陷类型总数


      /*
       * @Date 2022/06/24 15:22:57
       * 过滤缺陷信息
       */
      filterDefectDialogVisible: false,  // 是否打开过滤缺陷管理对话框
      defectLevelList: [],               // 缺陷等级字典
      defectSourceList: [],              // 缺陷来源字典值
      filterDefectForm: {                // 过滤缺陷表单
        fuzzyName: "",
        defectLevel: "DEFECT_LEVEL_ALL",
        defectSource: "DEFECT_SOURCE_ALL",
        pageNumber: 1,
        pageSize: 10
      },


      /*
       * @Date 2022/06/24 15:26:37
       * 新增 / 修改缺陷类型信息
       */
      addDefectDialogVisible: false,    // 是否打开新增缺陷管理对话框
      isAddDefect: true,                // 是否是新增缺陷类型
      defectForm: {
        defectName: "",
        defectLevel: "",
        defectDesc: ""
      },
      defectFormRules: {
        defectName: [{ required: true, message: "缺陷名称不能为空", trigger: "blur" }],
        defectLevel: [{ required: true, message: "缺陷等级不能为空", trigger: "blur" }]
      },


      /*
       * @Date 2022/06/24 16:05:42
       * 人工审阅信息
       */
      reviewActive: "normal",             // 是确认正常还是添加异常


      /*
       * @Date 2022/06/24 15:48:25
       * 给图片添加异常信息
       */
      filterDefectList: [],               // 过滤得到的所有缺陷类型集合
      defectKeyWord: "",                  // 搜索关键字
      selectTagNames: [],                 // 多选框中选择的类型
      saveDefectDialogVisible: false,     // 是否打开保存缺陷对话框
      recognizeTypeList: [],              // 可选择的识别类型列表
      defectStatusList: [],               // 可选择的缺陷状态列表
      allDefectList: [],                  // 所有缺陷类型列表
      defectRegionForm: {                 // 保存缺陷表单
        id: "",
        photoId: "",
        defectType: "",
        defectStatus: "",
        defectId: "",
        leftTopX: "",
        rightBottomX: "",
        leftTopY: "",
        rightBottomY: "",
        defectDetail: "",
        remark: ""
      },
      defectRegionFormRules: {
        defectType: [{ required: true, message: "识别类型不能为空！", trigger: "blur" }],
        defectStatus: [{ required: true, message: "缺陷状态不能为空！", trigger: "blur" }],
        defectId: [{ required: true, message: "缺陷类型不能为空！", trigger: "blur" }]
      },


      /*
       * @Date 2022/06/24 16:23:53
       * 其他
       */
      errandId: "",
      lineId: "",
      reloadPhotoDialogVisible: false,
      photoForm: {
        lineId: "",
        beginTime: "",
        endTime: "",
        errandId: ""
      },     
      photoFormRules: {},
      lineList: [],
      errandList: [],
      hasPhotoDates: [],
      photoDefects: [], 
      secret: "",          // md5加密密钥

      
      newAnno: "",         // 新的标记区域
      preUpdateAnno: "",    // 更新前的区域
      isUpdateDefectRegion: false // 是否更新缺陷标签
    };
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/24 16:27:40
     * @Description 根据状态获取图片
     */
    getPhotosByStatus(index) {
      let params = {
        lineId: this.lineId,
        errandId: this.errandId,
        photoStatus: this.typeActive,
      };
      getSelectPhotos(params).then(res => {
        this.totalPhoto = res.total;
        console.log(res);
        if (res.total != 0) {
          this.allPhotoList = res.rows;
        } else {
          this.allPhotoList = [];
        }
      }).catch(err => {
        this.$message.warning(res.data ? res.data : res.mesg);
        this.allPhotoList = [];
      }).finally(() => {
        if (this.allPhotoList.length > index) {
          this.getPhotoDetails(this.allPhotoList, index);
        } else {
          this.currentPhoto = "";
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:52:00
     * @Description 获取缺陷等级
     */
    getDefectLevelsList() {
      getDefectLevels().then(res => {
        console.log(res);
        if (res.code === "000000") {
          this.defectLevelList = res.data;
        }
      });
    },
    
    /**
     * @Author KR0288
     * @Date 2022/06/24 16:52:07
     * @Description 获取缺陷来源
     */
    getDefectSourceList() {
      getDefectSources().then(res => {
        console.log(res);
        if (res.code === "000000") {
          this.defectSourceList = res.data;
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:12:58
     * @Description 获取所有缺陷
     */
    getAllDefectList() {
      getAllDefect().then(res => {
        this.allDefectList = res.data;
        this.filterDefectList = this.allDefectList;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:15:19
     * @Description 获取识别类型
     */
    getRecognizeTypeList() {
      getRecognizeTypes().then(res => {
        if (res.code === "000000") {
          this.recognizeTypeList = res.data;
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:15:52
     * @Description 获取缺陷状态
     */
    getDefectStatusList() {
      getDefectStatus().then(res => {
        console.log(res);
        if (res.code === "000000") {
          this.defectStatusList = res.data;
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:26:45
     * @Description 点击左边图片状态tabs时调用
     */
    async handleClick(tab, e) {
      this.getPhotosByStatus(0);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:30:20
     * @Description 获取照片信息，地址，名称，缺陷信息
     */
    getPhotoDetails(photoList, index) {
      this.currentIndex = index;
      let photo = JSON.parse(JSON.stringify(photoList[index]));
      let defects = [];
      let params = {
        id: photoList[index].id
      };

      // 图片不同才显示加载框，否则会有个bug
      if (this.currentPhoto.photoName != photo.photoName) {
        this.isLoadingImage = true;
      }

      getselectSinglePhoto(params)
        .then(async res => {
          if (res.code === "000000") {
            this.loadImageSuccess = true;
            photo.photoUrl = await md5Encryption2(res.data.photoUrl)
            console.log(photo.photoUrl);
            defects = res.data.defectRegionList ? res.data.defectRegionList : [];
          } else {
            photo.photoUrl = "";
            this.loadImageSuccess = false;
            this.$message.warning(res.data ? res.data : res.mesg);
          }
        })
        .catch(err => {
          this.loadImageSuccess = false;
          this.$message.warning("加载图片发生异常！");
        })
        .finally(() => {
          this.currentPhoto = photo;
          this.photoDefects = defects.map(p => {
            p.isShowInPicutre = true;
            p.uuid = randomUtil.generateRandomString();
            return p;
          });

          this.newAnno = "";
          this.preUpdateAnno = "";
          this.selectedUUID = "";
          this.renderPhotoDefectAnnos("init");
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:32:12
     * @Description 大图展示上一张下一张按钮
     */
    handleShowButton(type) {
      this.isShowButton = type == "enter";
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:33:37
     * @Description 图片加载完成后回调，不包含data:image格式。回调参数,data={rawW,rawH,currentW,currentH}
     */
    imageLoadFun(data) {
      this.isLoadingImage = false;
      console.log("2.图片加载完成onImageLoad", data);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:33:59
     * @Description 当控件准备完成后回调，参数为uniqueKey
     */
    readyFun(uniqueKey) {
      console.log("1.当控件准备完成", uniqueKey);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:34:41
     * @Description 当画完一个标注框时回调，参数为data【标注数据】, uniqueKey
     */
    annoAddedFun(data, uniqueKey) {
      let x = data.position.x.substring(0, data.position.x.indexOf("%"));
      let x1 = data.position.x1.substring(0, data.position.x1.indexOf("%"));
      if (Math.abs(x - x1) < 0.1) {
        // 防止鼠标单击也绘制了标签
        this.renderPhotoDefectAnnos("rerender");
      } else if (this.newAnno && !data.operate) {
        // 逐个保存缺陷
        this.$message.warning("请先保存上一次打的标签！");
        this.renderPhotoDefectAnnos("rerender");
      } else if (!this.newAnno && !data.type) {
        // 如果不是重新渲染的标签，
        data.operate = "";
        // 标签类型有两种：saved：已保存，unsaved：未保存。
        data.type = "unsave";
        this.selectedUUID = data.uuid;
        this.newAnno = data;
        this.currentAnnoData.push(data);
      }

      // 代码添加的标签没有uuid，需要在新增的时候补上去
      this.currentAnnoData = this.currentAnnoData.map(d => {
        if (d.id == data.id) {
          d.uuid = data.uuid;
        }
        return d;
      });

      // 新增标签时，清空缺陷列表勾选框
      if (data.tagName === "请选择或添加新标签") {
        this.selectTagNames = [];
      }
    },

    
    /**
     * @Author KR0288
     * @Date 2022/06/24 16:41:14
     * @Description 当选中图片上的标注框时回调，参数为data【标注数据】, uniqueKey
     */
    annoSelectedFun(data, uniqueKey) {
      /*
       * @Date 2022/04/14 14:42:27
       * 在缺陷列表中选中缺陷
       */
      let tag = this.allDefectList.find(d => {
        return data.tagName.indexOf(d.defectName) > -1;
      });
      if (tag) {
        this.selectTagNames = [];
        this.selectTagNames.push(tag.defectName);
      }

      // 选中新的标签，先检查之前是否有改动的标签
      if (this.preUpdateAnno) {
        this.handlePreUpdateAnno(data, "select");
      }

      this.isUpdate = true;
      // 移动以前的画框，获取位置不会改变，此处修正位置
      this.allMarker = this.getAllMarker();
      var uuid = uniqueKey.getAttribute("data-uuid");
      this.selectedUUID = uuid;
      // console.log("5.当选中图片上的标注框时回调", data, uniqueKey, this.selectedUUID);
      var top = uniqueKey.style.top;
      var left = uniqueKey.style.left;
      var width = uniqueKey.style.width;
      var height = uniqueKey.style.height;
      var arr = [left, width, top, height];
      this.allMarker.forEach(item => {
        if (item.uuid == uuid) {
          arr[1] = (Number(arr[0].split("%")[0]) + Number(arr[1].split("%")[0])).toFixed(2) + "%";
          arr[3] = (Number(arr[2].split("%")[0]) + Number(arr[3].split("%")[0])).toFixed(2) + "%";
          item.position = {
            x: arr[0],
            x1: arr[1],
            y: arr[2],
            y1: arr[3]
          };
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:36:59
     * @Description 当标注框位置或者标框属性发生改动时回调，参数为data【标注数据】, uniqueKey
     */
    annoUpdatedFun(data, uniqueKey) {
      let isRender = data.findIndex(d => {
        return d.operate == "rerender";
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:38:42
     * @Description 当标注框主动渲染数据后时回调，参数为uniqueKey
     */
    annoDataFullLoadedFun(uniqueKey) {
      // console.log("当标注框主动渲染数据后时回调", uniqueKey);
      // var _this = this;
      // var del = document.getElementsByClassName("g-image-op-del");
      // del.forEach(dom => {
      //   dom.addEventListener("click", function() {
      //     if (_this.isWorkMode) {
      //       _this.savePhotoDefectMarker();
      //     }
      //   });
      // });
      // // 取消默认的中
      // console.log(document.querySelector(".annotation.selected"));
      // if (document.querySelector(".annotation.selected")) {
      //   var selectedAnno = document.querySelector(".annotation.selected");
      //   var dot = document.querySelectorAll(".annotation.selected>.resize-dot");
      //   dot.forEach(item => {
      //     var classStr = item.getAttribute("class");
      //     if (classStr.indexOf("hidden") == -1) {
      //       item.setAttribute("class", `${classStr} hidden`);
      //     }
      //   });
      //   selectedAnno.setAttribute("class", "annotation");
      // }
    },

    /**
     * @Author KR0132
     * @Date 2022/04/13 17:13:51
     * @Description 删除标签
     */
    annoRemoveFun(data) {
      console.log("移除标签！！！！");

      if (data.id) {
        this.handleDeleteDefect(data.id);
      }

      // 如果是新增标签，也要同步清空
      if (data.uuid == this.newAnno.uuid) {
        this.newAnno = "";
      }
    },

    /**
     * @Author KR0132
     * @Date 2022/04/13 17:12:34
     * @Description 更新标签
     */
    annoChangedFun(newValue, oldValue, uniqueKey) {
      // 选中新的标签，先检查之前是否有改动的标签
      if (this.preUpdateAnno) {
        this.handlePreUpdateAnno(newValue, "change");
      } else {
        this.preUpdateAnno = oldValue;
      }

      this.currentAnnoData = this.currentAnnoData.map(d => {
        if (d.uuid == newValue.uuid) {
          return newValue;
        } else {
          return d;
        }
      });

      // 更新标注区域
      this.photoDefects = this.photoDefects.map(pd => {
        if (pd.id == newValue.id) {
          pd.leftTopX = newValue.position.x;
          pd.leftTopY = newValue.position.y;
          pd.rightBottomX = newValue.position.x1;
          pd.rightBottomY = newValue.position.y1;
        }
        return pd;
      });

      // 新的标记区域
      if (this.newAnno && this.newAnno.uuid == newValue.uuid) {
        this.newAnno.position.x = newValue.position.x;
        this.newAnno.position.y = newValue.position.y;
        this.newAnno.position.x1 = newValue.position.x1;
        this.newAnno.position.y1 = newValue.position.y1;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:42:08
     * @Description 直接拖拽，修改标签名称时先对前一次的更改进行处理
     */
    handlePreUpdateAnno(data, type) {
      // 选择新标签前，先检查之前是否有变更标签
      if (this.selectedUUID && this.selectedUUID != data.uuid) {
        this.$confirm("是否保存先前修改的标签信息?", "提示", {
          confirmButtonText: "保存",
          cancelButtonText: "放弃",
          type: "warning"
        })
          .then(() => {
            if (this.newAnno && this.preUpdateAnno.uuid == this.newAnno.uuid) {
              this.savePhotoDefectMarker("insert");
            } else {
              this.savePhotoDefectMarker("update");
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消更改此标签"
            });
            this.photoDefects = this.photoDefects.map(d => {
              if (d.id == this.preUpdateAnno.id) {
                d.leftTopX = this.preUpdateAnno.position.x;
                d.leftTopY = this.preUpdateAnno.position.y;
                d.rightBottomX = this.preUpdateAnno.position.x1;
                d.rightBottomY = this.preUpdateAnno.position.y1;
              }
              return d;
            });

            console.log(this.photoDefects);

            if (this.newAnno.uuid == this.preUpdateAnno.uuid) {
              this.newAnno = "";
            }
            this.renderPhotoDefectAnnos("rerender");
          })
          .finally(() => {
            this.preUpdateAnno = "";
          });
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:26:39
     * @Description 切换图片
     */
    handleChangePhoto(type) {
      if (this.totalPhoto <= 0) {
        return;
      }

      let index = 0;
      switch (type) {
        case "prev":
          index = this.currentIndex - 1 < 0 ? this.totalPhoto - 1 : this.currentIndex - 1;
          break;
        case "next":
          index = this.currentIndex + 1 >= this.totalPhoto ? 0 : this.currentIndex + 1;
          break;
      }

      this.currentIndex = index;
      this.getPhotoDetails(this.allPhotoList, this.currentIndex);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:44:09
     * @Description 打开缺陷管理对话框
     */
    handleClickDefect() {
      this.diyDefectTableVisible = true;
      this.filterDefectForm = {
        fuzzyName: "",
        defectLevel: "DEFECT_LEVEL_ALL",
        defectSource: "DEFECT_SOURCE_ALL",
        pageNumber: 1,
        pageSize: 10
      };
      this.$nextTick(() => {
        this.getDefectList();
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:57:59
     * @Description 获取自定义缺陷
     */
    getDefectList() {
      let params = JSON.parse(JSON.stringify(this.filterDefectForm));

      filterDefect(params).then(res => {
        res.rows.forEach((item, index) => {
          this.$set(res.rows[index], "isRename", false);
        });
        this.manageDefectList = res.rows;
        this.totalDefect = res.total;
      });
    },

    /*
     * @Date 2022/02/11 18:40:25
     * 缺陷等级展示
     */
    showDefectLevel(defectLevel) {
      return this.defectLevelList.find(l => {
        if (l.key.indexOf(defectLevel) > -1) {
          return l;
        }
      }).value;
    },

    /*
     * @Date 2022/04/08 13:28:53
     * 展示缺陷来源
     */
    showDefectSource(defectSource) {
      return this.defectSourceList.find(l => {
        if (l.key.indexOf(defectSource) > -1) {
          return l;
        }
      }).value;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:49:43
     * @Description 过滤图片缺陷
     */
    applyFilterDefect(isApply) {
      if (!isApply) {
        this.$refs["filterDefectFormRef"].resetFields();
        this.filterDefectForm.pageNumber = 1;
      }
      this.filterDefectDialogVisible = false;
      this.getDefectList();
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:59:22
     * @Description 点击添加自定义缺陷按钮
     */
    addDiyDefectBtn() {
      // this.diyDefectTableVisible = false;
      this.addDefectDialogVisible = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:59:47
     * @Description 点击保存自定义缺陷按钮
     */
    saveDiyDefect(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isAddDefect) {
            saveDefect(this.defectForm).then(res => {
              if (res.code === "000000") {
                this.addDefectDialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.mesg
                });
                this.getDefectList();
                // this.diyDefectTableVisible = true;
              } else {
                this.$message({
                  type: "error",
                  message: res.mesg
                });
              }
            });
          } else {
            updateDefect(this.defectForm).then(res => {
              if (res.code === "000000") {
                this.addDefectDialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.mesg
                });
                this.getDefectList();
              } else {
                this.$message({
                  type: "error",
                  message: res.mesg
                });
              }
            });
          }
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:13:57
     * @Description 清空新增自定义缺陷表单
     */
    initdefectForm(formRef) {
      let form = this.$refs[formRef];
      if (form) {
        form.resetFields();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 16:02:04
     * @Description 点击修改自定义缺陷按钮
     */
    editBtn(row) {
      this.$nextTick(() => {
        this.defectForm.id = row.id;
        this.defectForm.defectName = row.defectName;
        this.defectForm.defectLevel = row.defectLevel;
        this.defectForm.defectDesc = row.defectDesc;        
      })
      this.addDefectDialogVisible = true;
      this.isAddDefect = false;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:00:53
     * @Description 删除自定义缺陷
     */
    deleteDefect(row) {
      this.$confirm("该操作将删除自定义缺陷，是否继续?", "提示", {
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        type: "warning"
      })
        .then(() => {
          deleteUserDefect({ id: row.id }).then(res => {
            if (res.code === "000000") {
              this.$message({
                type: "success",
                message: res.data
              });
              this.getDefectList();
            } else {
              this.$message({
                type: "error",
                message: res.data
              });
            }
          });
        })
        .catch(() => {
          console.log("取消删除");
        });
    },

    /*
     * @Date 2022/04/14 11:34:12
     * 缺陷类型
     */
    showRecognizeType(defectType) {
      return this.recognizeTypeList.find(l => {
        if (l.key.indexOf(defectType) > -1) {
          return l;
        }
      }).value;
    },

    /*
     * @Date 2022/04/14 11:36:44
     * 展示缺陷状态
     */
    showDefectStatus(defectStatus) {
      return this.defectStatusList.find(l => {
        if (l.key.indexOf(defectStatus) > -1) {
          return l;
        }
      }).value;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:29:23
     * @Description 显示或隐藏缺陷
     */
    handleShowOrHideDefect(index, currentStatus) {
      this.photoDefects[index].isShowInPicutre = !currentStatus;
      // 重新渲染缺陷
      this.renderPhotoDefectAnnos("rerender");
    },

    /**
     * @Author KR0132
     * @Date 2022/04/14 15:02:02
     * @Description 更新缺陷标签
     */
    handleUpdateDefect(defect) {
      this.isUpdateDefectRegion = true;
      this.defectRegionForm = JSON.parse(JSON.stringify(defect));
      this.saveDefectDialogVisible = true;
    },

    /**
     * @Author KR0132
     * @Date 2022/04/14 15:02:16
     * @Description 删除缺陷标签
     */
    handleDeleteDefect(id) {
      this.$confirm("是否确认删除此缺陷?", "提示", {
        confirmButtonText: "确 认",
        cancelButtonText: "取 消",
        type: "warning"
      })
        .then(() => {
          deletePhotoDefect({ regionId: id, photoId: this.currentPhoto.photoId })
            .then(res => {
              if (res.code === "000000") {
                this.$message.success(res.mesg);
              } else {
                this.$message.warning(res.mesg);
              }
            })
            .catch(err => {
              this.$message.warning("删除缺陷标签失败！");
            })
            .finally(() => {
              this.getPhotosByStatus(this.currentIndex);
            });
        })
        .catch(() => {
          // 取消删除，重新渲染所有标签
          this.renderPhotoDefectAnnos("rerender");
          this.$message({
            type: "info",
            message: "已取消删除此标签！"
          });
        });
    },

    /**
     * @Author KR0132
     * @Date 2022/04/15 08:50:40
     * @Description 保存图片为正常
     */
    savePhotoNormal() {
      let photo = this.allPhotoList[this.currentIndex];
      if (photo) {
        if (photo.photoStatus == "PHOTO_STATUS_DEFECT") {
          this.$confirm("当前图片包含缺陷标签，继续操作会删除所有缺陷标签，是否继续操作?", "提示", {
            confirmButtonText: "继 续",
            cancelButtonText: "取 消",
            type: "warning"
          })
            .then(() => {
              this.savePhoto(photo.id, photo.photoId, "PHOTO_STATUS_NORMAL")
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消操作！"
              });
            })
            .finally(() => {
             
            });
        }else {
          this.savePhoto(photo.id, photo.photoId, "PHOTO_STATUS_NORMAL")
        }
      } else {
        this.$message.warning("没有选中图片，无法保存图片审计状态！");
      }
    },

    savePhoto(id, photoId, photoStatus) {
      let params = { id: id, photoId: photoId, photoStatus: photoStatus };
          updatePhotoStatus(params)
            .then(res => {
              if (res.code === "000000") {
                this.$message.success("保存图片审计状态成功！");
                this.getPhotosByStatus(this.currentIndex);
              } else {
                this.$message.warning("保存图片审计状态失败！");
              }
            })
            .catch(err => {
              this.$message.error("保存图片审计状态发生异常！");
            });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:04:55
     * @Description 给选中的标记框打上标签
     */
    addTagToMarker() {
      if (this.selectTagNames.length > 1) {
        this.selectTagNames.splice(0, 1);
      }
      this.newDefectName = this.selectTagNames[0];
      let panel = this.$refs["aiPanel-editor"];
      
      if (document.querySelector(".annotation.selected") && panel) {
        this.preUpdateAnno = this.currentAnnoData.find(d => {
          return d.uuid == this.selectedUUID;
        });
        panel.setTag({
          tagName: this.newDefectName,
          tag: "0x0001"
        });
      } else {
        this.$message.warning("请先在图片标记");
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:06:59
     * @Description 点击保存按钮
     */
    savePhotoDefectMarker() {
      this.isUpdateDefectRegion = false;
      if (this.selectedUUID) {

        this.saveDefectDialogVisible = true;
        this.$nextTick(() => {
          let anno = this.currentAnnoData.find(d => {
            return d.uuid == this.selectedUUID;
          });
          
          if (anno) {
            this.defectRegionForm.leftTopX = anno.position.x;
            this.defectRegionForm.leftTopY = anno.position.y;
            this.defectRegionForm.rightBottomX = anno.position.x1;
            this.defectRegionForm.rightBottomY = anno.position.y1;
          }
          this.defectRegionForm.photoId = this.allPhotoList[this.currentIndex].photoId;
          this.defectRegionForm.errandId= this.allPhotoList[this.currentIndex].errandId;
          let currentTag = this.selectTagNames[0];
          if (currentTag) {
            let tag = this.allDefectList.find(d => {
              return d.defectName == currentTag;
            });
            if (tag) {
              this.defectRegionForm.defectId = tag.id;
            }
          }

          // 处理已保存过的缺陷
          if (anno.id) {
            this.isUpdateDefectRegion = true;
            let photoDefect = this.photoDefects.find(d => {
              return d.id == anno.id;
            });
            if (photoDefect) {
              this.defectRegionForm.id = anno.id;
              this.defectRegionForm.defectId = photoDefect.defectId;
              this.defectRegionForm.defectType = photoDefect.defectType;
              this.defectRegionForm.defectStatus = photoDefect.defectStatus;
              this.defectRegionForm.defectDetail = photoDefect.defectDetail;
              this.defectRegionForm.remark = photoDefect.remark;
            }
          }
        });
      } else {
        this.$message.warning("请先点击选择一个缺陷标签");
      }

      // // 获取当前组件所有缺陷标记
      // this.allMarker = this.getAllMarker();
      // // 将组件数据格式转化为后端需要的数据格式
      // var defectList = this.vueMarkerTurnToDefectRegionMap(this.allMarker);

      // let params = {
      //   id: this.currentPhoto.id,
      //   defectList: defectList
      // };
      // var isNoneName = false; //是否没选标签
      // this.allMarker.forEach(item => {
      //   if (item.tagName == "请选择或添加新标签") {
      //     console.log("isNoneName", isNoneName);
      //     isNoneName = true;
      //   }
      // });
      // if (isNoneName) {
      //   this.$message.warning("请打上标签后点击保存");
      // } else {
      //   // postDefectDetailsByName(params).then(res => {
      //   //   if (res.code === "000000") {
      //   //     this.$message({
      //   //       type: "success",
      //   //       message: "标签保存成功"
      //   //     });
      //   //     this.isUpdate = false;
      //   //     this.getPhotosByStatus();

      //   //   }
      //   // });
      // }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:10:57
     * @Description 提交保存缺陷标签事件
     */
    hanldeSaveDefect(formRef, isSave) {
      if(isSave) {
        this.$refs[formRef].validate(valid => {
          if (valid) {
            // 更新标签
            if (this.isUpdateDefectRegion) {
              updatePhotoDefect(this.defectRegionForm)
                .then(res => {
                  if (res.code === "000000") {
                    this.$message.success(res.mesg);
                  } else {
                    this.$message.warning(res.mesg);
                  }
                })
                .catch(err => {
                  this.$message.warning("更新缺陷标签失败！");
                })
                .finally(() => {
                  this.saveDefectDialogVisible = false;
                  this.isUpdateDefectRegion = false;
                  this.getPhotosByStatus(this.currentIndex);
                });
            } else {
              // 新增标签
              addPhotoDefect(this.defectRegionForm)
                .then(res => {
                  if (res.code === "000000") {
                    this.$message.success(res.mesg);
                    this.saveDefectDialogVisible = false;
                    let tag = this.allDefectList.find(d => d.id == this.defectRegionForm.defectId);
                    if (tag) {
                      this.selectTagNames = [];
                      this.selectTagNames.push(tag.defectName);

                      let panel = this.$refs["aiPanel-editor"];
                      if (document.querySelector(".annotation.selected") && panel) {
                        panel.setTag({
                          tagName: tag.defectName,
                          tag: "0x0001"
                        });
                      }
                    }

                    this.preUpdateAnno = "";
                  } else {
                    this.$message.info(res.mesg);
                  }
                })
                .catch(err => {
                  this.$message.warning("保存缺陷发生异常！");
                })
                .finally(() => {
                  this.saveDefectDialogVisible = false;
                  this.isUpdateDefectRegion = false;
                  this.getPhotosByStatus(this.currentIndex);
                });
            }
          }
        });        
      } else {
        this.saveDefectDialogVisible = false;
      }

    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:08:53
     * @Description 关闭对话窗口
     */
    handleCloseDialog(formRef) {
      let ref = this.$refs[formRef];
      if (ref) {
        ref.resetFields();
        ref.clearValidate();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:23:51
     * @Description 把后端返回的缺陷标记格式化为组件需要的格式
     */
    renderPhotoDefectAnnos(operate) {
      this.currentAnnoData = [];
      this.photoDefects.filter(item => {
        return item.isShowInPicutre;
      }).forEach((item, index) => {
        let initCheckItem = {
          uuid: item.uuid ? item.uuid : randomUtil.generateRandomString(),
          operate: operate,
          type: "saved",
          id: item.id,
          tag: index,
          tagName: item.defectName,
          position: {
            x: item.leftTopX,
            x1: item.rightBottomX,
            y: item.leftTopY,
            y1: item.rightBottomY
          }
        };
        this.currentAnnoData.push(initCheckItem);
      });

      if (this.newAnno) {
        this.newAnno.operate = operate;
        this.currentAnnoData.push(this.newAnno);
      }

      // 渲染到图片上
      this.renderMarker(this.currentAnnoData);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:24:00
     * @Description 把组件返回的数据格式化为后台需要的格式
     */
    vueMarkerTurnToDefectRegionMap(markerArr) {
      // let markerObj = {};
      // markerArr.forEach(item => {
      //   markerObj[item.tagName] = [item.position.x, item.position.x1, item.position.y, item.position.y1];
      // });

      return markerArr.map(item => {
        let defect = this.filterDefectList.find(d => {
          if (item.tagName.indexOf(d.defectName) > -1) {
            return d;
          }
        });

        return {
          defectId: defect.id,
          leftTopX: item.position.x,
          rightBottomX: item.position.x1,
          leftTopY: item.position.y,
          rightBottomY: item.position.y1
        };
      });

      // return markerObj;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:24:28
     * @Description 渲染缺陷到图片上
     */
    renderMarker(defectArr) {
      this.clearMarker(); //渲染数据之前先清除
      let panel = this.$refs["aiPanel-editor"];
      if (panel) {
        panel.getMarker().renderData(defectArr); //渲染缺陷，接收一个数组
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:24:41
     * @Description 清除缺陷数据
     */
    clearMarker() {
      let panel = this.$refs["aiPanel-editor"];
      if (panel) {
        panel.getMarker().clearData();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:25:47
     * @Description 获取当前图片上的所有标签信息
     */
    getAllMarker() {
      let panel = this.$refs["aiPanel-editor"];
      if (panel) {
        return panel.getMarker().getData();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 17:28:32
     * @Description 模糊查询缺陷
     */
    fuzzyGetDefect() {
      if (this.defectKeyWord === null || this.defectKeyWord === "" || this.defectKeyWord === undefined) {
        this.filterDefectList = this.allDefectList;
      } else {
        // 根据关键字过滤缺陷
        this.filterDefectList = this.allDefectList.filter(item => item.defectName.search(this.defectKeyWord) >= 0);
      }
    },

    // 处理查询图片中的日期按键切换
    datePickerBtnClick(date) {
      if (this.currentLineId === undefined) {
        return;
      }

      let params = {
        lineId: this.currentLineId,
        dateOfMonth: date ? date : moment().format("YYYY-MM-DD")
      };
      getHasPhotoDateAndTowers(params).then(res => {
        this.hasPhotoDates = res.data.dates;
        this.hasPhotoTowers = res.data.towers;
      });
    },

    // 处理用户选择日期
    changeDate(data) {
      if (data.tag === "beginDate") {
        this.photoForm.beginDate = data.date;
      } else if (data.tag === "endDate") {
        this.photoForm.endDate = data.date;
      }
    },

    // 加载更多图片
    loadMorePhoto() {},
  },

  mounted() {
    this.errandId = this.$route.query.errandId;
    this.lineId = this.$route.query.lineId;
    this.getAllDefectList();
    this.getDefectLevelsList();
    this.getDefectSourceList();
    this.getRecognizeTypeList();
    this.getDefectStatusList();
    this.$nextTick(() => {
      this.handleClick();
    });
  },

  activated() {
    this.errandId = this.$route.query.errandId;
    this.lineId = this.$route.query.lineId;
    // this.getAllDefectList();
    // this.getDefectLevelsList();
    // this.getDefectSourceList();
    // this.getRecognizeTypeList();
    // this.getDefectStatusList();
    this.$nextTick(() => {
      this.handleClick();
    });
  },
};
</script>
<style lang="scss" scoped>
.analysisDetails-page {
  height: calc(100% - 5vh);
  padding: 0 1vw;
  color: #fff;
  .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
      height: 8vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .left {
        width: 13.6vw;
        height: 100%;
        margin-right: 1vw;
      }
      .middle {
        flex-grow: 1;
        height: 100%;
        .photo-area {
          .prev-button,
          .next-button {
            font-size: .1rem !important; 
          }
        }
      }
      .right {
        width: 13.6vw;
        height: 100%;
        margin-left: 1vw;
        .top-button {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 3px;
          margin-top: 1vh;
          margin-bottom: 1vh;
          .el-button {
            padding: 1.2vh 1vh;
          }
        }
        .photo-defect {
          padding-bottom: 1vh;
          .photo-defect-list {
            padding: 0;
            margin: 0;
            li {
              list-style: none;
              height: 4vh;
              line-height: 4vh;
              padding-left: 1vh;
            }
          }
          .save-button {
            text-align: center;
          }
        }
      }
    }
    // align-items: center;
  }
}
.li-actived {
  color: #e4ff00;
  border-left: 2px solid #e4ff00;
  color: #e4ff00;
  background: linear-gradient(90deg, rgba(228, 255, 0, 0.5), rgba(228, 255, 0, 0));
}

.table-index-icon {
  margin-right: 0 !important;
}
</style>
